import React, { useEffect, useLayoutEffect, useState } from "react"
import Popup from "reactjs-popup"
import "./modal.scss"

export default function Modal({
  modalTitle,
  modalContent,
  modalButton,
  popupClass,
  defaultOpen = false,
  setDefaultOpen,
}) {
  const [open, setOpen] = useState(defaultOpen)
  const closeModal = () => {
    setOpen(false)
    if (setDefaultOpen) setDefaultOpen(false)
  }

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true)
    }
    return () => {
      setOpen(false)
      document.body.style.overflow = "auto"
    }
  }, [defaultOpen])

  useLayoutEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [open])

  return (
    <>
      <span role="button" tabIndex={0} onClick={() => setOpen(o => !o)}>
        {modalButton}
      </span>
      <Popup
        className={popupClass ?? ""}
        open={open}
        closeOnDocumentClick={false}
        onClose={closeModal}
      >
        <div className="modal">
          <span
            className="close"
            role="button"
            tabIndex={0}
            onClick={closeModal}
          >
            &times;
          </span>
          <div className="popup-title">{modalTitle}</div>
          <div className="popup-content">{modalContent}</div>
        </div>
      </Popup>
    </>
  )
}
